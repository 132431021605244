<template>
    <div class="grid justify-content-center">
        <div class="col-12 md:col-12">
            <div class="card text-center mt-7">
                <Avatar class="bg-orange-500 text-white" icon="pi pi-check-circle" size="large" />
                <div class="text-2xl my-4 font-bold text-orange-500">
                    Email verification completed.
                </div>
                <Divider></Divider>
                <div class="text-center">
                    <router-link :to="`/index/login`">
                        <Button label="Continue" />
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>